import { ResourcePack } from "../../models/Admin";
import { useBoolean } from "../../hooks/useBoolean";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    Input,
} from "@fluentui/react-components";

import { MarkdownEditor } from "../../components/MarkdownEditor";
import { ApiValidationError, getEntityWithValidation } from "../../models/ValidatedEntity";
import * as api from "../../utils/api";

type ResourcePackNew = Omit<ResourcePack, "id">;

export const AdminResourcePackNew = () => {
    const navigate = useNavigate();

    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [resourcePack, setResourcePack] = useState<ResourcePackNew>({ name: "", description: null });
    const [error, setError] = useState<Error | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setActivityMessage("saving...");
        try {
            const response = await api.post<ResourcePackNew, ResourcePack>("api/admin/resource-packs", resourcePack);
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                navigate(`/admin/resource-packs/${response.id}`);
            }
        } catch (e) {
            setError(e as Error);
        } finally {
            setActivityMessage(null);
        }
    };

    const entityWithValidation = resourcePack && getEntityWithValidation(resourcePack, validationErrors);

    const styles = useDialogStyles();

    const content = entityWithValidation ? (
        <>
            {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
            <Field
                label="Name"
                hint="Name of the resource pack"
                orientation="horizontal"
                required={true}
                validationMessage={entityWithValidation.propertyMessages.name}
            >
                <Input defaultValue={resourcePack.name} onChange={(_, data) => setResourcePack({ ...resourcePack, name: data.value })} />
            </Field>
            <Field
                label="Description"
                hint="Description of the resource pack"
                orientation="horizontal"
                required={true}
                validationMessage={entityWithValidation.propertyMessages.description}
            >
                <MarkdownEditor
                    value={resourcePack.description ?? ""}
                    onChange={(data) => setResourcePack({ ...resourcePack, description: data.value })}
                />
            </Field>
            <div className={styles.actions}>
                <Button appearance="primary" onClick={saveEntity}>
                    Save
                </Button>
                <Button appearance="secondary" onClick={showDiscardConfirmationDialog}>
                    Cancel
                </Button>
                <Dialog open={isDiscardConfirmationDialogOpen}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Discard changes?</DialogTitle>
                            <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                            <DialogActions>
                                <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                    Cancel
                                </Button>
                                <Button appearance="primary" onClick={() => navigate("/admin/resource-packs")}>
                                    Discard
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </div>
        </>
    ) : (
        <></>
    );
    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/resource-packs">resource packs</Link> / &lt;new&gt;
            </h1>
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>Error: {error.message}</div>}
            <div className={styles.root}>{content}</div>
        </>
    );
};
