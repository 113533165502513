import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    Input,
    Checkbox,
} from "@fluentui/react-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "../../hooks/useBoolean";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import { Event, EventCoupon } from "../../models/Admin";
import * as api from "../../utils/api";
import { ApiValidationError, EntityWithValidation, getEntityWithValidation } from "../../models/ValidatedEntity";

type EventCouponUpdate = Omit<EventCoupon, "id">;

export const AdminEventCouponEdit = () => {
    const { eventSlug, couponId } = useParams() as { eventSlug: string; couponId: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const [eventTitle, setEventTitle] = useState<string>(eventSlug);
    const [eventCoupon, setEventCoupon] = useState<EventCoupon | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);
    const [showSavedBanner, setShowSavedBanner] = useState(false);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            setError(null);

            try {
                const e = await api.get<Event>(`api/admin/events/${eventSlug}`);
                setEventTitle(e.title);
                const coupon = await api.get<EventCoupon>(`api/admin/events/${eventSlug}/coupons/${couponId}`, {
                    jsonReviver: api.dateReviver,
                });
                setEventCoupon(coupon);
                setValidationErrors([]);
            } catch (e) {
                setError(e as Error);
            }
            setActivityMessage(null);
        }
        execute();
    }, [eventSlug, couponId]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setActivityMessage("saving...");
        try {
            if (!eventCoupon) {
                throw new Error("Cannot save null event coupon");
            }
            const { id, ...eventCouponUpdate } = eventCoupon;
            const response = await api.put<EventCouponUpdate, EventCoupon>(
                `api/admin/events/${eventSlug}/coupons/${id}`,
                eventCouponUpdate,
                {
                    jsonReviver: api.dateReviver,
                }
            );
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                setEventCoupon(response);
                setValidationErrors([]);
                setShowSavedBanner(true);
                setTimeout(() => setShowSavedBanner(false), 1000);
            }
            setError(null);
            setActivityMessage(null);
        } catch (e) {
            setError(e as Error);
            setActivityMessage(null);
        }
    };

    const entityWithValidation = eventCoupon && getEntityWithValidation(eventCoupon, validationErrors);

    const styles = useDialogStyles();

    const header = (
        <h1>
            <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
            <Link to={`/admin/events/${eventSlug}`}>{eventTitle}</Link> / <Link to={`/admin/events/${eventSlug}/coupons`}>coupons</Link> /{" "}
            {eventCoupon?.title ?? couponId}
        </h1>
    );

    return (
        <>
            {header}
            {showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>{error.message}</div>}

            {entityWithValidation && (
                <AdminEventCouponEditComponent
                    isNew={false}
                    entityWithValidation={entityWithValidation}
                    setEventCoupon={setEventCoupon}
                    onSaveEntity={saveEntity}
                    onDiscardChanges={() => navigate(`/admin/events/${eventSlug}/coupons`)}
                />
            )}
        </>
    );
};

export function AdminEventCouponNew() {
    const { eventSlug } = useParams() as { eventSlug: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [eventTitle, setEventTitle] = useState<string>(eventSlug);

    const [eventCoupon, setEventCoupon] = useState<EventCoupon>({
        id: "",
        eventId: "",
        stripeId: "",
        title: "",
        active: false,
        validFrom: null,
        validUntil: null,
    });
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            setError(null);

            try {
                const e = await api.get<Event>(`api/admin/events/${eventSlug}`);
                setEventTitle(e.title);
                setValidationErrors([]);
            } catch (e) {
                setError(e as Error);
            }
            setActivityMessage(null);
        }
        execute();
    }, [eventSlug]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setActivityMessage("saving...");
        try {
            if (!eventCoupon) {
                throw new Error("Cannot save null event coupon");
            }
            const response = await api.post<EventCoupon, EventCoupon>(`api/admin/events/${eventSlug}/coupons`, eventCoupon);
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                navigate(`/admin/events/${eventSlug}/coupons/${response.id}`);
                return;
            }
            setError(null);
            setActivityMessage(null);
        } catch (e) {
            setError(e as Error);
            setActivityMessage(null);
        }
    };
    const entityWithValidation = eventCoupon && getEntityWithValidation(eventCoupon, validationErrors);

    const styles = useDialogStyles();

    const header = (
        <h1>
            <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
            <Link to={`/admin/events/${eventSlug}`}>{eventTitle} </Link> / <Link to={`/admin/events/${eventSlug}/coupons`}>couphons</Link> /
            &lt;new&gt;
        </h1>
    );
    return (
        <>
            {header}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>{error.message}</div>}

            {entityWithValidation && (
                <AdminEventCouponEditComponent
                    isNew={true}
                    entityWithValidation={entityWithValidation}
                    setEventCoupon={setEventCoupon}
                    onSaveEntity={saveEntity}
                    onDiscardChanges={() => navigate(`/admin/events/${eventSlug}/coupons`)}
                />
            )}
        </>
    );
}

function AdminEventCouponEditComponent({
    entityWithValidation,
    isNew,
    setEventCoupon,
    onSaveEntity,
    onDiscardChanges,
}: {
    entityWithValidation: EntityWithValidation<EventCoupon>;
    isNew: boolean;
    setEventCoupon: (e: EventCoupon) => void;
    onSaveEntity: () => void;
    onDiscardChanges: () => void;
}) {
    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);

    const eventCoupon = entityWithValidation.entity;
    const styles = useDialogStyles();

    function dateToInputString(date: Date | null): string {
        if (!date) {
            return "";
        }
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hour = date.getHours().toString().padStart(2, "0");
        const minute = date.getMinutes().toString().padStart(2, "0");
        const second = date.getSeconds().toString().padStart(2, "0");
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    }
    return (
        <>
            <div className={styles.root + " event-form"}>
                {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
                <Field
                    label="Id"
                    hint="This is the coupon code that you give to customers"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.id}
                >
                    <Input
                        defaultValue={eventCoupon.id}
                        readOnly={!isNew}
                        onChange={(_, data) => {
                            if (isNew) {
                                console.log({ isNew, value: data.value });
                                setEventCoupon({ ...eventCoupon, id: data.value });
                            }
                        }}
                    />
                </Field>
                <Field
                    label="Title"
                    hint="Title shown in the admin pages"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.title}
                >
                    <Input defaultValue={eventCoupon.title} onChange={(_, data) => setEventCoupon({ ...eventCoupon, title: data.value })} />
                </Field>
                <Field
                    label="Stripe Id"
                    hint="The ID of the coupon in Stripe"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.stripeId}
                >
                    <Input
                        defaultValue={eventCoupon.stripeId}
                        onChange={(_, data) => setEventCoupon({ ...eventCoupon, stripeId: data.value })}
                    />
                </Field>
                <Field
                    label="Is Active"
                    hint="A coupon must be marked as Active to be displayed"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.active}
                >
                    <Checkbox
                        defaultChecked={eventCoupon.active}
                        onChange={(_, data) => setEventCoupon({ ...eventCoupon, active: data.checked === true })}
                    />
                </Field>
                <Field
                    label="Valid From"
                    hint="If set then the coupon will only be usable after this date"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.validFrom}
                >
                    <Input
                        type="datetime-local"
                        defaultValue={dateToInputString(eventCoupon.validFrom)}
                        onChange={(_, data) => setEventCoupon({ ...eventCoupon, validFrom: new Date(data.value) })}
                    />
                </Field>
                <Field
                    label="Valid Until"
                    hint="If set then the coupon will only be usable before this date"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.validUntil}
                >
                    <Input
                        type="datetime-local"
                        defaultValue={dateToInputString(eventCoupon.validUntil)}
                        onChange={(_, data) => setEventCoupon({ ...eventCoupon, validUntil: new Date(data.value) })}
                    />
                </Field>
                <div className={styles.actions}>
                    <Button appearance="primary" onClick={onSaveEntity}>
                        Save
                    </Button>
                    <Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
                        Cancel
                    </Button>
                    <Dialog open={isDiscardConfirmationDialogOpen}>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Discard changes?</DialogTitle>
                                <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                                <DialogActions>
                                    <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                        Cancel
                                    </Button>
                                    <Button appearance="primary" onClick={onDiscardChanges}>
                                        Discard
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </div>
            </div>
        </>
    );
}
