import { useEffect, useState } from "react";
import { CustomerListSummary } from "../../models/Admin";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody, TableCellLayout } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import * as api from "../../utils/api";

export const AdminCustomerList = () => {
    const [customers, setCustomers] = useState<CustomerListSummary[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                setCustomers(await api.get<CustomerListSummary[]>(`api/admin/customers`, { jsonReviver: api.dateReviver }));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, []);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / customers
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / customers
                </h1>
                <div>loading...</div>
            </>
        );
    }

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / customers
            </h1>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Email</TableHeaderCell>
                        <TableHeaderCell>StripeId</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {customers?.map((customer) => (
                        <TableRow key={customer.id}>
                            <TableCell>
                                <TableCellLayout appearance="primary">
                                    <Link to={`/admin/customers/${customer.id}`}>{customer.name}</Link>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>{customer.email}</TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <a href={customer.link}>{customer.stripeId}</a>
                            </TableCell>
                            <TableCell>
                                <Link to={`/admin/bookings?customerId=${customer.id}`}>bookings...</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
