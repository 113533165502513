import { protectedResources } from "../authConfig";
import { msalInstance } from '../index'
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { ApiValidationError } from "../models/ValidatedEntity";


export interface ApiValidationErrorResponse {
    errors: ApiValidationError[]
}

function getToken(request: any) {
    const currentAccounts = msalInstance.getAllAccounts();

    let accountId = "";
    // let username = "";

    if (currentAccounts.length === 0) {
        return null;
    } else if (currentAccounts.length > 1) {
        // Add your account choosing logic here
        console.warn("Multiple accounts detected.");
        return null;
    } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId;
        // username = currentAccounts[0].username;
    }


    /**
    * See here for more information on account retrieval: 
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
    */

    request.account = msalInstance.getAccountByHomeId(accountId);
    return msalInstance.acquireTokenSilent(request)
        .catch(error => {
            console.warn(error);
            console.warn("silent token acquisition fails. acquiring token using redirect");
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                // return msalInstance.acquireTokenPopup(request)
                return msalInstance.acquireTokenRedirect(request)
                    .then(response => {
                        console.log(response);
                        return response;
                    }).catch(error => {
                        console.error(error);
                    });
            } else {
                console.warn(error);
            }
        });
}
async function getApiAuthHeaderValue() {
    const tokenRequest = {
        scopes: protectedResources.api.scopes.general,
    };

    let response = await getToken(tokenRequest);
    if (response) {
        return `Bearer ${response.accessToken}`;
    }
    // trigger login if we don't get credentials (e.g. if they've expired)
    const loginRequest = {
        scopes: protectedResources.api.scopes.general,
    };
    await msalInstance.loginRedirect(loginRequest);
    return null;
}
async function callApiRaw(endpoint: string, method: string, data?: any) {
    const bearerToken = await getApiAuthHeaderValue();
    if (!bearerToken) {
        throw new Error("token not set");
    }
    const headers = new Headers();
    headers.append("Authorization", bearerToken);
    if (data) headers.append('Content-Type', 'application/json');

    let options = {
        method: method,
        headers: headers,
        body: data ? JSON.stringify(data) : null,

    };

    return await fetch(endpoint, options);
}

export function dateReviver(key: any, value: any) {
    if (typeof value === "string") {
        const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        if (dateRegex.test(value)) {
            return new Date(Date.parse(value));
        }
    }
    return value;
}

export interface GetOptions {
    jsonReviver?: (key: any, value: any) => any;
}
export async function get<TResponse>(url: string, options?: GetOptions): Promise<TResponse> {
    const fullUrl = protectedResources.api.endpoint + url
    const response = await callApiRaw(fullUrl, "GET");
    if (!response.ok) {
        throw new Error(`Error calling API: ${response.status} - ${response.statusText}`);
    }
    const responseBody = await response.text();
    return JSON.parse(responseBody, options?.jsonReviver) as TResponse;
}

export interface PostOptions {
    jsonReviver?: (key: any, value: any) => any;
}
export async function post<TBody, TResponse>(url: string, body: TBody, options?: PostOptions): Promise<TResponse | ApiValidationErrorResponse> {
    const fullUrl = protectedResources.api.endpoint + url
    const response = await callApiRaw(fullUrl, "POST", body);

    if (!response.ok) {
        if (response.status === 422) {
            return await response.json() as ApiValidationErrorResponse;
        }
        throw new Error(`Error calling API: ${response.status} - ${response.statusText}`);
    }
    const responseBody = await response.text();
    return JSON.parse(responseBody, options?.jsonReviver) as TResponse;
}

export interface PutOptions {
    jsonReviver?: (key: any, value: any) => any;
}
export async function put<TBody, TResponse>(url: string, body: TBody, options?: PutOptions): Promise<TResponse | ApiValidationErrorResponse> {
    const fullUrl = protectedResources.api.endpoint + url
    const response = await callApiRaw(fullUrl, "PUT", body);

    if (!response.ok) {
        if (response.status === 422) {
            return await response.json() as ApiValidationErrorResponse;
        }
        throw new Error(`Error calling API: ${response.status} - ${response.statusText}`);
    }
    if (response.status === 204) {
        return undefined as TResponse;
    }
    const responseBody = await response.text();
    return JSON.parse(responseBody, options?.jsonReviver) as TResponse;
}

async function _delete(url: string): Promise<void> {
    const fullUrl = protectedResources.api.endpoint + url
    const response = await callApiRaw(fullUrl, "DELETE");
    if (!response.ok) {
        throw new Error(`Error calling API: ${response.status} - ${response.statusText}`);
    }
}

export { _delete as delete }; // delete is a reserved word but allowed in exports
