import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ResourcePack, Resource, EventIdAndTitle, EventSummary } from "../../models/Admin";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "../../hooks/useBoolean";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import {
    Field,
    Input,
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Tooltip,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    Combobox,
    ComboboxProps,
    Option,
} from "@fluentui/react-components";
import { Edit32Regular, Delete32Regular } from "@fluentui/react-icons";
import { MarkdownEditor } from "../../components/MarkdownEditor";
import * as api from "../../utils/api";
import { ApiValidationError, getEntityWithValidation } from "../../models/ValidatedEntity";

type ResourcePackNew = Omit<ResourcePack, "id">;

export const AdminResourcePackEdit = () => {
    const { packId } = useParams() as { packId: string };
    const navigate = useNavigate();
    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);
    const [isDeleteConfirmationDialogOpen, { setTrue: showDeleteConfirmationDialog, setFalse: hideDeleteConfirmationDialog }] =
        useBoolean(false);
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [packName, setPackName] = useState<string | null>(null);
    const [resourcePackNewEvent, setResourcePackNewEvent] = useState<EventIdAndTitle | null>(null);
    const [resourcePack, setResourcePack] = useState<ResourcePack | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [confirmDeleteEvent, setConfirmDeleteEvent] = useState<EventIdAndTitle | null>(null);

    const [events, setEvents] = useState<EventSummary[]>([]);
    const [resourcePackResources, setResourcePackResources] = useState<Resource[]>([]);

    const [showSavedBanner, setShowSavedBanner] = useState(false);

    const [resourcePackEvents, setResourcePackEvents] = useState<EventIdAndTitle[]>([]);

    const [resourcePackEventFilter, setResourcePackEventFilter] = useState<string>("");

    const onResourcePackEventFilterChange: ComboboxProps["onChange"] = (e) => {
        const value = e.target.value.trim();
        setResourcePackEventFilter(value);
        setResourcePackNewEvent(null);
    };
    const onResourcePackEventOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
        if (!data.optionValue) {
            return;
        }
        const event = events?.find((e) => e.id === data.optionValue) ?? null;
        setResourcePackNewEvent(event);
    };

    const filterLower = resourcePackEventFilter.toLowerCase();
    const resourcePackNewEventList = events
        ?.filter((event) => {
            return event.title.toLowerCase().indexOf(filterLower) >= 0 && !resourcePackEvents?.find((e) => e.id === event.id);
        })
        .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

    useEffect(() => {
        async function execute() {
            // TODO - execute these in parallel
            setActivityMessage("loading...");
            try {
                const resourcePack = await api.get<ResourcePack>(`api/admin/resource-packs/${packId}`);
                setResourcePack(resourcePack);
                setPackName(resourcePack?.name ?? null);

                setResourcePackResources(await api.get<Resource[]>(`api/admin/resource-packs/${packId}/resources`));
                setEvents(await api.get<EventSummary[]>("api/admin/events"));

                const resourceEvents = await api.get<EventIdAndTitle[]>(`api/admin/resource-packs/${packId}/events`);
                setResourcePackEvents(resourceEvents);
            } catch (e) {
                setError(e as Error);
            } finally {
                setActivityMessage(null);
            }
        }
        execute();
    }, [packId]);

    const saveEntity = async (e?: any) => {
        e?.preventDefault();
        setActivityMessage("saving...");
        try {
            if (!resourcePack) {
                throw new Error("Resource pack not loaded");
            }
            const { id, ...resourcePackUpdate } = resourcePack;
            const response = await api.put<ResourcePackNew, ResourcePack>(`api/admin/resource-packs/${packId}`, resourcePackUpdate);
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                setResourcePack(response);
                setValidationErrors([]);
                setShowSavedBanner(true);
                setTimeout(() => setShowSavedBanner(false), 1000);
            }
        } catch (e) {
            setError(e as Error);
        } finally {
            setActivityMessage(null);
        }
    };
    const deleteEntity = async (e?: any) => {
        e?.preventDefault();
        hideDeleteConfirmationDialog();
        setActivityMessage("deleting...");
        await api.delete(`api/admin/resource-packs/${packId}`);
        navigate("/admin/resource-packs");
    };
    const deleteResourcePackEvent = async (resourceEventId: string) => {
        setActivityMessage("deleting...");
        try {
            await api.delete(`api/admin/resource-packs/${packId}/events/${resourceEventId}`);
            setResourcePackEvents(await api.get<EventIdAndTitle[]>(`api/admin/resource-packs/${packId}/events`));
        } catch (e) {
            setError(e as Error);
        } finally {
            setActivityMessage(null);
        }
    };
    const addResourcePackEvent = async () => {
        setActivityMessage("adding event...");
        try {
            if (!resourcePackNewEvent) {
                throw new Error("No event selected");
            }
            const response = await api.put<void, void>(`api/admin/resource-packs/${packId}/events/${resourcePackNewEvent.id}`, undefined);
            if (response instanceof Object) {
                throw new Error("Error adding event");
            }
            setResourcePackEvents(await api.get<EventIdAndTitle[]>(`api/admin/resource-packs/${packId}/events`));

            setError(null);
            setResourcePackNewEvent(null);
            setResourcePackEventFilter("");
        } catch (error) {
            setActivityMessage(null);
            setError(error as Error);
        } finally {
            setActivityMessage(null);
        }
    };

    const entityWithValidation = resourcePack && getEntityWithValidation(resourcePack, validationErrors);

    const styles = useDialogStyles();

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/resource-packs">resource packs</Link> / {packName}
            </h1>
            {showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>Error: {error.message}</div>}

            <h2 className="spaced">Resource Pack</h2>
            <div className={styles.root + " resource-pack-form"}>
                {entityWithValidation && resourcePackResources && (
                    <>
                        {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
                        <Field
                            label="Name"
                            hint="Name of the resource pack"
                            orientation="horizontal"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.name}
                        >
                            <Input
                                defaultValue={resourcePack.name}
                                onChange={(_, data) => setResourcePack({ ...resourcePack, name: data.value })}
                            />
                        </Field>
                        <Field
                            label="Description"
                            hint="Description of the resource pack"
                            orientation="horizontal"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.description}
                        >
                            <MarkdownEditor
                                value={resourcePack.description ?? ""}
                                onChange={(data) => setResourcePack({ ...resourcePack, description: data.value })}
                            />
                        </Field>
                        <div className={styles.actions}>
                            <Button appearance="primary" onClick={saveEntity}>
                                Save
                            </Button>
                            <Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
                                Cancel
                            </Button>
                            {resourcePackResources.length === 0 ? (
                                <Button appearance="secondary" className={styles.dangerButton} onClick={showDeleteConfirmationDialog}>
                                    Delete
                                </Button>
                            ) : (
                                <Tooltip content="Cannot delete resource pack with resources." relationship="label">
                                    <Button appearance="secondary" className={styles.dangerButtonDisabled} disabled>
                                        Delete
                                    </Button>
                                </Tooltip>
                            )}
                            <Dialog open={isDiscardConfirmationDialogOpen}>
                                <DialogSurface>
                                    <DialogBody>
                                        <DialogTitle>Discard changes?</DialogTitle>
                                        <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                                        <DialogActions>
                                            <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                                Cancel
                                            </Button>
                                            <Button appearance="primary" onClick={() => navigate("/admin/resource-packs")}>
                                                Discard
                                            </Button>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                            <Dialog open={isDeleteConfirmationDialogOpen}>
                                <DialogSurface>
                                    <DialogBody>
                                        <DialogTitle>Delete Resource Pack?</DialogTitle>
                                        <DialogContent>
                                            WARNING: deleting cannot be undone. Are you sure you want to delete this resource pack?
                                        </DialogContent>
                                        <DialogActions>
                                            <Button appearance="secondary" onClick={hideDeleteConfirmationDialog}>
                                                Cancel
                                            </Button>
                                            <Button appearance="primary" className={styles.dangerButton} onClick={deleteEntity}>
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                        </div>
                    </>
                )}
            </div>
            <h2 className="spaced">Resources</h2>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell style={{ width: "3em" }}>Order</TableHeaderCell>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Link</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {resourcePackResources?.map((resource) => (
                        <TableRow key={resource.id}>
                            <TableCell>{resource.order}</TableCell>
                            <TableCell>{resource.name}</TableCell>
                            <TableCell>{resource.link}</TableCell>
                            <TableCell>
                                <Tooltip content="Edit resource" relationship="label">
                                    <Button
                                        icon={<Edit32Regular />}
                                        onClick={() => navigate(`/admin/resource-packs/${packId}/resources/${resource.id}`)}
                                    ></Button>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button onClick={() => navigate(`/admin/resource-packs/${packId}/resources/$new`)}>New Resource</Button>

            <h2 className="spaced">Linked Events</h2>
            <div>Anyone who has purchased a linked event (or has an active subscription) will get access to this resource pack</div>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Event name</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {resourcePackEvents?.map((event) => (
                        <TableRow key={event.id}>
                            <TableCell>{event.title}</TableCell>
                            <TableCell>
                                <Tooltip content="Remove event from resource pack" relationship="label">
                                    <Button icon={<Delete32Regular />} onClick={() => setConfirmDeleteEvent(event)}></Button>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={confirmDeleteEvent != null}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Remove Link to Event ?</DialogTitle>
                        <DialogContent>Are you sure you want remove the link from this event ({confirmDeleteEvent?.title})?</DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={() => setConfirmDeleteEvent(null)}>
                                Cancel
                            </Button>
                            <Button
                                appearance="primary"
                                className={styles.dangerButton}
                                onClick={() => {
                                    deleteResourcePackEvent(confirmDeleteEvent!.id);
                                    setConfirmDeleteEvent(null);
                                }}
                            >
                                Remove
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <div className={styles.root}>
                <Field label="Event" hint="Choose the event to link to this resource pack" orientation="horizontal" required={true}>
                    <Combobox
                        placeholder="Select event"
                        onChange={onResourcePackEventFilterChange}
                        onOptionSelect={onResourcePackEventOptionSelect}
                        value={resourcePackNewEvent?.title ?? resourcePackEventFilter}
                    >
                        {resourcePackNewEventList?.map((event) => (
                            <Option key={event.id} value={event.id}>
                                {event.title}
                            </Option>
                        ))}
                        {resourcePackNewEventList.length === 0 ? (
                            <Option key="" text="">
                                No results found
                            </Option>
                        ) : null}
                    </Combobox>
                </Field>
            </div>
            <Tooltip content="Link event to resource pack" relationship="label">
                <Button disabled={!resourcePackNewEvent} onClick={() => addResourcePackEvent()}>
                    Link event
                </Button>
            </Tooltip>
        </>
    );
};
