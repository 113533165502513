import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import * as MarkdownIt from 'markdown-it';

import { loginRequest } from '../authConfig';
import { Resource, ResourcePack } from '../models/User';
import { Button } from '@fluentui/react-components';
import * as api from "../utils/api";


const md = MarkdownIt.default();

const UserResourcePackShowContent = () => {
	const { packId } = useParams() as { packId: string };
	const [resourcePack, setResourcePack] = useState<ResourcePack| null>(null);
	const [resources, setResources] = useState<Resource[]>([]);
	const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

	useEffect(() => {
		async function execute() {
			try {
				setIsCallingApi(true);
				setResourcePack(await api.get<ResourcePack>(`api/resource-packs/${packId}`))
				setResources(await api.get<Resource[]>(`api/resource-packs/${packId}/resources`))
			}
			catch(e) {
				setError(e as Error);
			}
			finally{
				setIsCallingApi(false);
			}
		}
		execute();
	}, [packId])

	if (error) {
		return <>
			<div>Error loading : {error.message}</div>
		</>
	}
	if (isCallingApi) {
		return <>
			<div>loading...</div>
		</>
	}

	return (
		<>
			<div className='user-resource-pack'>
				{resourcePack ? <>
					<h2>{resourcePack?.name}</h2>
					<div className='description' dangerouslySetInnerHTML={{ __html: md.render(resourcePack.description ?? "") }}>
					</div>
				</> : <></>
				}

				{resources?.map((resource) => (
					<div key={resource.id} className='resource-link-button'>
						<a href={resource.link} target='_blank' rel="noreferrer">
							<Button appearance='primary' size='large'>
								{resource.name}
							</Button>
						</a>
					</div>
				))}
			</div>
		</>
	)
}

export const UserResourcePackShow = () => {
	const { instance } = useMsal();

	return (
		<>
			<a href="/">Back to list...</a>
			<AuthenticatedTemplate>
				<UserResourcePackShowContent />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>This site allows you to access resources for bookings via <a href="https://bookings.emilieleeks.com">bookings.emilieleeks.com</a>.</p>
				<Button appearance="primary" onClick={() => instance.loginRedirect(loginRequest)} className="ml-auto">Sign In</Button>
			</UnauthenticatedTemplate>
		</>
	);
};