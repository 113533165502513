import { makeStyles } from "@fluentui/react-components";

export const useDialogStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "550px",
        marginLeft: "1em",
        paddingTop: "1em",
        height: "auto",
        boxSizing: "border-box",
        "> *": {
            textOverflow: "ellipsis",
        },
        "> :not(:first-child)": {
            marginTop: "0px",
        },
        "> *:not(.ms-StackItem)": {
            flexShrink: 1,
        },
    },
    actions: {
        alignItems: "end",
        paddingTop: "2em",
        paddingBottom: "2em",
    },
    errorBanner: {
        paddingLeft: "1em",
        paddingTop: "1em",
        paddingBottom: "1em",
        fontWeight: "bold",
        backgroundColor: "lightpink",
    },
    savedBanner: {
        paddingLeft: "1em",
        paddingTop: "1em",
        paddingBottom: "1em",
        fontWeight: "bold",
        backgroundColor: "lightgreen",
        marginRight: "1em",
    },
    savingBanner: {
        paddingLeft: "1em",
        paddingTop: "1em",
        paddingBottom: "1em",
        fontWeight: "bold",
        backgroundColor: "lightgreen",
        marginRight: "1em",
    },
    dangerButton: {
        color: "white",
        backgroundColor: "red",
        marginRight: "1em",
    },
    dangerButtonDisabled: {
        color: "white",
        backgroundColor: "darkred",
        marginRight: "1em",
    },
    buttonGap: {
        marginRight: "1em",
    },
    field: {
        marginTop: "1em",
    },
});

export const useTableStyles = makeStyles({
    thead: {
        fontWeight: "bold",
    },
});
