import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    Input,
    Checkbox,
    Dropdown,
    Option,
} from "@fluentui/react-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "../../hooks/useBoolean";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import { Event, EventOption, EventOptionType } from "../../models/Admin";
import * as api from "../../utils/api";
import { ApiValidationError, EntityWithValidation, getEntityWithValidation } from "../../models/ValidatedEntity";

type EventOptionUpdate = Omit<EventOption, "id">;

export const AdminEventOptionEdit = () => {
    const { eventSlug, optionId } = useParams() as { eventSlug: string; optionId: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const [eventTitle, setEventTitle] = useState<string>(eventSlug);
    const [eventOption, setEventOption] = useState<EventOption | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);
    const [showSavedBanner, setShowSavedBanner] = useState(false);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            setError(null);

            try {
                const e = await api.get<Event>(`api/admin/events/${eventSlug}`);
                setEventTitle(e.title);
                const option = await api.get<EventOption>(`api/admin/events/${eventSlug}/options/${optionId}`, {
                    jsonReviver: api.dateReviver,
                });
                setEventOption(option);
                setValidationErrors([]);
            } catch (e) {
                setError(e as Error);
            }
            setActivityMessage(null);
        }
        execute();
    }, [eventSlug, optionId]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setActivityMessage("saving...");
        try {
            if (!eventOption) {
                throw new Error("Cannot save null event option");
            }
            const { id, ...eventOptionUpdate } = eventOption;
            const response = await api.put<EventOptionUpdate, EventOption>(
                `api/admin/events/${eventSlug}/options/${id}`,
                eventOptionUpdate,
                {
                    jsonReviver: api.dateReviver,
                }
            );
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                setEventOption(response);
                setValidationErrors([]);
                setShowSavedBanner(true);
                setTimeout(() => setShowSavedBanner(false), 1000);
            }
            setError(null);
            setActivityMessage(null);
        } catch (e) {
            setError(e as Error);
            setActivityMessage(null);
        }
    };

    const entityWithValidation = eventOption && getEntityWithValidation(eventOption, validationErrors);

    const styles = useDialogStyles();

    const header = (
        <h1>
            <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
            <Link to={`/admin/events/${eventSlug}`}>{eventTitle}</Link> / <Link to={`/admin/events/${eventSlug}/options`}>options</Link> /{" "}
            {eventOption?.title ?? optionId}
        </h1>
    );

    return (
        <>
            {header}
            {showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>{error.message}</div>}

            {entityWithValidation && (
                <AdminEventOptionEditComponent
                    isNew={false}
                    entityWithValidation={entityWithValidation}
                    setEventOption={setEventOption}
                    onSaveEntity={saveEntity}
                    onDiscardChanges={() => navigate(`/admin/events/${eventSlug}/options`)}
                />
            )}
        </>
    );
};

export function AdminEventOptionNew() {
    const { eventSlug } = useParams() as { eventSlug: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [eventTitle, setEventTitle] = useState<string>(eventSlug);

    const [eventOption, setEventOption] = useState<EventOption>({
        id: "",
        eventId: "",
        stripeId: "",
        title: "",
        type: "one-off",
        sortOrder: 0,
        numberOfPlacesUsed: 1,
        active: false,
        validFrom: null,
        validUntil: null,
    });
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            setError(null);

            try {
                const e = await api.get<Event>(`api/admin/events/${eventSlug}`);
                setEventTitle(e.title);
                setValidationErrors([]);
            } catch (e) {
                setError(e as Error);
            }
            setActivityMessage(null);
        }
        execute();
    }, [eventSlug]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setActivityMessage("saving...");
        try {
            if (!eventOption) {
                throw new Error("Cannot save null event option");
            }
            const response = await api.post<EventOption, EventOption>(`api/admin/events/${eventSlug}/options`, eventOption);
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                navigate(`/admin/events/${eventSlug}/options/${response.id}`);
                return;
            }
            setError(null);
            setActivityMessage(null);
        } catch (e) {
            setError(e as Error);
            setActivityMessage(null);
        }
    };
    const entityWithValidation = eventOption && getEntityWithValidation(eventOption, validationErrors);

    const styles = useDialogStyles();

    const header = (
        <h1>
            <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
            <Link to={`/admin/events/${eventSlug}`}>{eventTitle} </Link> / <Link to={`/admin/events/${eventSlug}/options`}>options</Link> /
            &lt;new&gt;
        </h1>
    );
    return (
        <>
            {header}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>{error.message}</div>}

            {entityWithValidation && (
                <AdminEventOptionEditComponent
                    isNew={true}
                    entityWithValidation={entityWithValidation}
                    setEventOption={setEventOption}
                    onSaveEntity={saveEntity}
                    onDiscardChanges={() => navigate(`/admin/events/${eventSlug}/options`)}
                />
            )}
        </>
    );
}

function parseType(type?: string): EventOptionType {
    switch (type) {
        case "subscription":
            return "subscription";
        case "one-off":
            return "one-off";
        default:
            throw new Error(`Unknown event option type ${type}`);
    }
}

function AdminEventOptionEditComponent({
    entityWithValidation,
    isNew,
    setEventOption,
    onSaveEntity,
    onDiscardChanges,
}: {
    entityWithValidation: EntityWithValidation<EventOption>;
    isNew: boolean;
    setEventOption: (e: EventOption) => void;
    onSaveEntity: () => void;
    onDiscardChanges: () => void;
}) {
    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);

    const eventOption = entityWithValidation.entity;
    const styles = useDialogStyles();

    function dateToInputString(date: Date | null): string {
        if (!date) {
            return "";
        }
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hour = date.getHours().toString().padStart(2, "0");
        const minute = date.getMinutes().toString().padStart(2, "0");
        const second = date.getSeconds().toString().padStart(2, "0");
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    }
    return (
        <>
            <div className={styles.root + " event-form"}>
                {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
                <Field
                    label="Id"
                    hint="The url for the booking is: .../events/<event-slug>/<id>"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.id}
                >
                    <Input
                        defaultValue={eventOption.id}
                        readOnly={!isNew}
                        onChange={(_, data) => {
                            if (isNew) {
                                console.log({ isNew, value: data.value });
                                setEventOption({ ...eventOption, id: data.value });
                            }
                        }}
                    />
                </Field>
                <Field
                    label="Title"
                    hint="The title for the booking option (e.g. Single Person)
"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.title}
                >
                    <Input defaultValue={eventOption.title} onChange={(_, data) => setEventOption({ ...eventOption, title: data.value })} />
                </Field>
                <Field
                    label="Type"
                    orientation="horizontal"
                    className={styles.field}
                    required={false}
                    validationMessage={entityWithValidation.propertyMessages.type}
                >
                    <Dropdown
                        defaultValue={eventOption.type.toString()}
                        onOptionSelect={(_, data) => {
                            setEventOption({ ...eventOption, type: parseType(data.optionValue) });
                        }}
                    >
                        <Option text="subscription" value="subscription">
                            Subscription
                        </Option>
                        <Option text="one-off" value="one-off">
                            One-Off
                        </Option>
                    </Dropdown>
                </Field>
                <Field
                    label="Stripe Id"
                    hint="The stripe ID for the SKU with the pricing information for this booking option"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.stripeId}
                >
                    <Input
                        defaultValue={eventOption.stripeId}
                        onChange={(_, data) => setEventOption({ ...eventOption, stripeId: data.value })}
                    />
                </Field>
                <Field
                    label="Number of Places Used"
                    hint="The number of places taken up by this booking option. Use 1 unless this is an option for a couple etc"
                    orientation="horizontal"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.numberOfPlacesUsed}
                >
                    <Input
                        defaultValue={eventOption.numberOfPlacesUsed.toString()}
                        onChange={(_, data) => setEventOption({ ...eventOption, numberOfPlacesUsed: parseInt(data.value) })}
                    />
                </Field>
                <Field
                    label="Sort Order"
                    hint="When there are multiple options displayed this controls the order they are displayed in. E.g. an item with Sort Order 1 is shown before 2"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.sortOrder}
                >
                    <Input
                        defaultValue={eventOption.sortOrder.toString()}
                        onChange={(_, data) => setEventOption({ ...eventOption, sortOrder: parseInt(data.value ?? "0") })}
                    />
                </Field>
                <Field
                    label="Is Active"
                    hint="A booking option must be marked as Active to be displayed"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.active}
                >
                    <Checkbox
                        defaultChecked={eventOption.active}
                        onChange={(_, data) => setEventOption({ ...eventOption, active: data.checked === true })}
                    />
                </Field>
                <Field
                    label="Valid From"
                    hint="If set then the booking option will only be displayed after this date"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.validFrom}
                >
                    <Input
                        type="datetime-local"
                        defaultValue={dateToInputString(eventOption.validFrom)}
                        onChange={(_, data) => setEventOption({ ...eventOption, validFrom: new Date(data.value) })}
                    />
                </Field>
                <Field
                    label="Valid Until"
                    hint="If set then the booking option will only be displayed before this date"
                    orientation="horizontal"
                    className={styles.field}
                    validationMessage={entityWithValidation.propertyMessages.validUntil}
                >
                    <Input
                        type="datetime-local"
                        defaultValue={dateToInputString(eventOption.validUntil)}
                        onChange={(_, data) => setEventOption({ ...eventOption, validUntil: new Date(data.value) })}
                    />
                </Field>
                <div className={styles.actions}>
                    <Button appearance="primary" onClick={onSaveEntity}>
                        Save
                    </Button>
                    <Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
                        Cancel
                    </Button>
                    <Dialog open={isDiscardConfirmationDialogOpen}>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Discard changes?</DialogTitle>
                                <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                                <DialogActions>
                                    <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                        Cancel
                                    </Button>
                                    <Button appearance="primary" onClick={onDiscardChanges}>
                                        Discard
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </div>
            </div>
        </>
    );
}
